import React,{Component} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { contractV1PDF } from '../../documents/contractPDF'
import { getUsers } from '../../../actions/userAction'
import { FaList, FaRegFilePdf, FaRegEdit, FaTrash, FaRegEye, FaSearch, FaUndoAlt } from 'react-icons/fa';
import { Dropdown, Container, Row, Col, Button, InputGroup, FormControl, Form } from 'react-bootstrap';
import { } from '../../../actions/userAction'
import _ from 'lodash';
import './css/styles.css';

const customStyles = {
  headRow: {
    style: {
      border: 'none',
    },
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      borderRadius: '25px',
      outline: '1px solid #FFFFFF',
    },
  },
  pagination: {
    style: {
      border: 'none',
    },
  },
};

class UsersListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: [],
      searchText: '',
      foundUsers: [],
      searchTextIsEmpty: true,
      searchOptions: [
        {
            title: 'First Name',
            value: 'user_first_name',
        },
        {
            title: 'Last Name',
            value: 'user_last_name',
        },
        {
            title: 'Email',
            value: 'user_email',
        },
        {
            title: 'Role',
            value: 'role',
        }
      ],
      selectedOptionSearch: 'user_first_name',
    };
  }

  componentDidMount(){
    const { usersList } = this.props
    usersList().then((resp) => {
        this.setUsersData(resp.users)
    })
  }

  setUsersData = (data) => {
    this.setState({
        data,
        foundUsers: data
    });
  }

  findUsers = (curatedSearchString, users) => users.filter(
    (users) => {
      let curatedUsersValue = users.title.toLowerCase()
      curatedUsersValue = _.deburr(curatedUsersValue)
      return curatedUsersValue.includes(curatedSearchString)
    },
  )

  findUsers = (curatedSearchString, users) => {
    const {
      selectedOptionSearch,
    } = this.state

    return users.filter(
      (user) => {
        let curatedUserValue = selectedOptionSearch === 'role'
          ? user[selectedOptionSearch].role_name.toLowerCase()
          : user[selectedOptionSearch].toLowerCase()
          curatedUserValue = _.deburr(curatedUserValue)

        return curatedUserValue.includes(curatedSearchString)
      },
    )
  }

  handleSearchInputChange = (event) => {
    const targetValue = event.target.value;
    this.setState({ searchText: targetValue });
    this.setState({ searchTextIsEmpty: targetValue === '' })
  }

  handleChangeOption = (event) => {
    const targetValue = event.target.value;
    this.setState({ selectedOptionSearch: targetValue });
  }
  
  handleReset = (event) => {
    event.preventDefault();
    const { data } = this.state
    this.setState({
      foundUsers: data,
      searchText: '',
      searchTextIsEmpty: true,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      searchText, data,
    } = this.state

    let curatedSearchString = searchText.toLowerCase()
    curatedSearchString = _.deburr(curatedSearchString)

    const foundUsers = this.findUsers(curatedSearchString, data)

    this.setState({ foundUsers })
  }

  searchText = () => {
    const {
      searchText, searchTextIsEmpty, selectedOptionSearch, searchOptions
    } = this.state
    return (
      <Col xs={12} lg={4}>
        <form onSubmit={this.handleSubmit}>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <Form.Control as="select" style={{borderTopRightRadius: '0', borderBottomRightRadius: '0', zIndex: '1'}} onChange={(e) => this.handleChangeOption(e)} value={selectedOptionSearch}>
                    {searchOptions.map(item => (
                    <option key={item.value} value={item.value}>
                        {item.title}
                    </option>
                    ))}
                </Form.Control>
            </InputGroup.Prepend>
            <FormControl value={searchText} onChange={this.handleSearchInputChange} placeholder="Enter a Word" aria-describedby="basic-addon1" />
            <InputGroup.Append>
                <Button onClick={this.handleSubmit} variant="outline-secondary"><FaSearch style={{ pointerEvents: 'none' }} /></Button>
                <Button onClick={this.handleReset} hidden={searchTextIsEmpty} variant="outline-secondary"><FaUndoAlt  style={{ pointerEvents: 'none' }}/></Button>
            </InputGroup.Append>
          </InputGroup>
        </form>
      </Col>
    )
  }

  columnsDataTable = ([
    {
      name: 'First Name',
      selector: 'user_first_name',
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: 'user_last_name',
      sortable: true,
    },
    {
        name: 'Email',
        selector: 'user_email',
        sortable: true,
    },
    {
        name: 'Role',
        selector: 'role.role_name',
        sortable: true,
    },
    {
        name: 'Status',
        selector: 'user_active',
        cell: row => (row.user_active ? 'Active' : 'Inactive' ),
        sortable: true,
    },
    
    {
      name: 'Actions',
      cell: row => (
        <Dropdown >
          <Dropdown.Toggle      
            className="ddl-menu"   
          >
            <FaList className="icon-ddl-menu" />
          </Dropdown.Toggle>
  
          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1"><FaRegEdit className="icon-ddl-menu" /> Edit</Dropdown.Item>
            <Dropdown.Item href="#/action-1"><FaTrash className="icon-ddl-menu" /> Remove</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      allowOverflow: true,
      button: true,
    },
  ]);

  render() {
    const { loading, foundUsers } = this.state;
        
    return (
      <Container>
        <Row style={{ borderColor: 'rgb(0,0,0,0.5) !important' }}>
          <Col>
            <DataTable
              columns={this.columnsDataTable}
              progressPending={loading}
              data={foundUsers}
              pagination
              customStyles={customStyles}
              highlightOnHover
              pointerOnHover
              subHeader
              subHeaderComponent={this.searchText()}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapDispatchToProps = dispatch => ({
    usersList: () => dispatch(getUsers()),
  });
  
export default connect(null, mapDispatchToProps)(withRouter(UsersListPage));

