export const GET_USERS_LIST_FULFILLED = 'GET_USERS_LIST_FULFILLED'
export const GET_USERS_LIST_PENDING = 'GET_USERS_LIST_PENDING'
export const GET_USERS_LIST_REJECTED = 'GET_USERS_LIST_REJECTED'
export const POST_USER_REJECTED = 'POST_USER_REJECTED'
export const POST_USER_FULFILLED = 'POST_USER_FULFILLED'
export const POST_USER_PENDING = 'POST_USER_PENDING'
export const UPDATE_USER_REJECTED = 'UPDATE_USER_REJECTED'
export const UPDATE_USER_FULFILLED = 'UPDATE_USER_FULFILLED'
export const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING'
export const DELETE_USER_PENDING = 'DELETE_USER_PENDING'
export const DELETE_USER_FULFILLED = 'DELETE_USER_FULFILLED'
export const DELETE_USER_REJECTED = 'DELETE_USER_REJECTED'
