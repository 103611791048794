import JsPDF from 'jspdf';
import { timesNewRomans } from './fonts/times-new-roman';

const xContent = 1
const yReset = 1.18
const xLineStart = 35
const xLineEnd = 555
const yLineBreak = 0.2
const yParagraphBreak = 0.3
const yParagraphBreakList = 30
const fontSize = 12
const width = 8.5
const heigth = 30
let yCounter = 1.18

const row1pag1v1 = "WESTERN PACIFIC BUILDERS, INC."
const row2pag1v1 = "HOME IMPROVEMENT CONTRACT CALIFORNIA SOLAR DISCLOSURE PAGE"
const row3pag1v1 = "The TOTAL COST for the solar energy system (including financing and energy/power cost if applicable) is: $ 19,077.00"
const row4pag1v1 = 'To make a complaint against a contractor who installs this system and/or the home improvement salesperson who sold this system, contact the Contractors State License Board ("CSLB") through their website at www.cslb.ca.gov (search: “complaint form”), by telephone at 800-321CSLB (2752), or by writing to P.O. Box 26000, Sacramento, CA 95826.'
const row5pag1v1 = "If the attached contract was not negotiated at the contractor's place of business, you have a Three-Day Right to Cancel the contract, pursuant to Business and Professions Code (BPC) section 7159, as noted below. For further details on canceling the contract, see the Notice of Cancellation, which must be included in your contract."
const row6pag1v1 = "Three-Day Right to Cancel"
const row7pag1v1 = "You, the buyer, have the right to cancel this contract within three business days. You may cancel by e-mailing, mailing, faxing, or delivering a written notice to the contractor at the contractor's place of business by midnight of the third business day after you received a signed and dated copy of the contract that includes this notice. Include your name, your address, and the date you received the signed copy of the contract and this notice."
const row8pag1v1 = "WESTERN PACIFIC HOME IMPROVEMENT CONTRACT"
const row9pag1v1 = 'This Home Improvement Contract (“Agreement”) dated: is made between Western Pacific Builders, INC. and its affiliates (collectively “Contractor”), located at 16555 Sherman Way Suite C Lake Balboa, CA 91406, and Emilia Ferreira(“You” and/or “Your”) for work to be performed 16032 Gault St Van Nuys, CA 91406 Phone: (818)424-7360, Email: adapferreira@gmail.com (“Project”).'

const row1pag2v1 = "This Agreement relates to Your purchase of a:" 

const row2pag2v1 = "A 3.1 Kw Solar Energy System"

const row3pag2v1 = "SYSTEM DETAILS: Western Pacific Energy System:" 

const row4pag2v1 = "Quantity: (10) 310KW (DC): 3.10 KW"   

const row5pag2v1 = "Solar Energy system cost: $19,077.00"  

const row6pag2v1 = "CONTRACT PRICE: $19,077.00 - FINANCE CHARGE: Assumed by Contractor"   

const row7pag2v1 = "Regardless of the system You purchase, we promise You:  A world-class experience with the nation’s leading home improvement contractor."  

const row8pag2v1 = "•   Top notch system installation with a workmanship warranty and a manufacturer’s product warranty. Customer service/support available at (858)333-9341 or customercare@buildwesternpacific.com"  

const row9pag2v1 = "List of Documents to be Incorporated into This Contract: * Applicable Warranty(ies)"  

const row10pag2v1 = "Name and Registration Number of Salesperson: Reynaldo Antonio Guzman California State License No.#119471 SP Expiration 12/31/2019"  

const row11pag2v1 = "GENERAL TERMS & CONDITIONS What Are Contractor's Responsibilities?"   

const row12pag2v1 = "Right to Stop Work: If you project is being paid in cash, Contractor has the right to stop work on the Project if any amount due to Contractor has not been paid. Contractor reserves the right to hire subcontractors (who will be bound by the terms of this Agreement) and will instruct its subcontractors to stop work for nonpayment."   

const row13pag2v1 = "Release: Once payment has been made for any portion of the work, the Contractor shall, prior to any further payment being made, give You a full and unconditional release from any claim or mechanic’s lien authorized under any applicable state law for the portion of the work for which payment has been made."   

const row14pag2v1 = "Service Charge: Contractor will charge a 1.5% service charge per month, which will be added to all sums which are past due. If You fail to timely pay Contractor, it will be considered a material breach of this Agreement."   

const row15pag2v1 = "Notification of Concealed Conditions: Contractor shall promptly notify You of latent physical conditions at the Project or physical conditions differing from those normally recognized by Contractor. Any work You ask Contractor to perform due to such conditions will be considered “extra work” (see below)."   

const row16pag2v1 = "Cleanup; Surplus Materials: Contractor will remove any debris stemming from its work on Your property and will leave the property in a neat, broom clean condition. Contractor will remove any surplus materials left over from the Project, as those belong to Contractor. You will be issued no monetary credit for surplus materials."   


export const contractV1PDF = (data) => {
    const doc = new JsPDF('p', 'in', [612, 792]);

    doc.addFileToVFS("times-new-roman.ttf", timesNewRomans)

    doc.addFont("times-new-roman.ttf", "times-new-roman", "Regular");
    doc.setFont("times-new-roman","Regular");
    doc.setFontSize(7.5);
    doc.page = 1

    /**********************************************************************************************************************/
    /*                                                 START PAGE 1                                                       */                                                        
    /**********************************************************************************************************************/

    doc.setFontSize(fontSize);
    doc.text(xContent, yCounter, row1pag1v1);
    yCounter += yParagraphBreak;

    doc.text(xContent, yCounter, row2pag1v1);
    yCounter += yParagraphBreak;

    const split1 = doc.splitTextToSize(row3pag1v1, (width - ((xContent) * 2)),)
    for (let y = 0; y < split1.length; y += 1) {
        doc.text(xContent, yCounter, split1[y]);
        if(y < split1.length - 1){
            yCounter += yLineBreak
        }
    }
    yCounter += yParagraphBreak;

    const split2 = doc.splitTextToSize(row4pag1v1, (width - ((xContent) * 2)),)
    for (let y = 0; y < split2.length; y += 1) {
        doc.text(xContent, yCounter, split2[y]);
        if(y < split2.length - 1){
            yCounter += yLineBreak
        }    
    }
    yCounter += yParagraphBreak;

    const split3 = doc.splitTextToSize(row5pag1v1, (width - ((xContent) * 2)),)
    for (let y = 0; y < split3.length; y += 1) {
        doc.text(xContent, yCounter, split3[y]);
        if(y < split3.length - 1){
            yCounter += yLineBreak
        }    
    }
    yCounter += yParagraphBreak;

    doc.text(xContent, yCounter, row6pag1v1);
    yCounter += yParagraphBreak;

    const split4 = doc.splitTextToSize(row7pag1v1, (width - ((xContent) * 2)),)
    for (let y = 0; y < split4.length; y += 1) {
        doc.text(xContent, yCounter, split4[y]);
        if(y < split4.length - 1){
            yCounter += yLineBreak
        }    
    }
    yCounter += yParagraphBreak;

    doc.text(xContent, yCounter, row8pag1v1);
    yCounter += yParagraphBreak;

    const split5 = doc.splitTextToSize(row9pag1v1, (width - ((xContent) * 2)),)
    for (let y = 0; y < split5.length; y += 1) {
        doc.text(xContent, yCounter, split5[y]);
        if(y < split5.length - 1){
            yCounter += yLineBreak
        }    
    }
    yCounter += yParagraphBreak;

    /**********************************************************************************************************************/
    /*                                                  END PAGE 1                                                        */
    doc.page += 1
    doc.addPage();
    yCounter = yReset
    /*                                                 START PAGE 2                                                       */                                                        
    /**********************************************************************************************************************/

    doc.text(xContent, yCounter, row1pag2v1);
    yCounter += yParagraphBreak;

    doc.text(xContent, yCounter, row2pag2v1);
    yCounter += yParagraphBreak;

    doc.text(xContent, yCounter, row3pag2v1);
    yCounter += yParagraphBreak;

    doc.text(xContent, yCounter, row4pag2v1);
    yCounter += yParagraphBreak;

    doc.text(xContent, yCounter, row5pag2v1);
    yCounter += yParagraphBreak;

    doc.text(xContent, yCounter, row6pag2v1);
    yCounter += yParagraphBreak;

    const split1Pag2 = doc.splitTextToSize(row7pag2v1, (width - ((xContent) * 2)),)
    for (let y = 0; y < split1Pag2.length; y += 1) {
        doc.text(xContent, yCounter, split1Pag2[y]);
        if(y < split1Pag2.length - 1){
            yCounter += yLineBreak
        }    
    }
    yCounter += yParagraphBreak;

    doc.save('Contrato.pdf');
}

