import React,{Component} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { contractV1PDF } from '../../documents/contractPDF'
import { FaList, FaRegFilePdf, FaRegEdit, FaTrash, FaRegEye, FaSearch, FaUndoAlt } from 'react-icons/fa';
import { Dropdown, Container, Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import _ from 'lodash';
import './css/styles.css';

const customStyles = {
  headRow: {
    style: {
      border: 'none',
    },
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      borderRadius: '25px',
      outline: '1px solid #FFFFFF',
    },
  },
  pagination: {
    style: {
      border: 'none',
    },
  },
};

class ContractsListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: [{ id: 1, title: 'Conan the Barbarian', year: '1982' } ],
      searchText: '',
      foundContracts: [{ id: 1, title: 'Conan the Barbarian', year: '1982' } ],
      searchTextIsEmpty: true,
    };
  }

  findContracts = (curatedSearchString, contracts) => contracts.filter(
    (contracts) => {
      let curatedContractsValue = contracts.title.toLowerCase()
      curatedContractsValue = _.deburr(curatedContractsValue)
      return curatedContractsValue.includes(curatedSearchString)
    },
  )

  handleSearchInputChange = (event) => {
    const targetValue = event.target.value;
    this.setState({ searchText: targetValue });
    this.setState({ searchTextIsEmpty: targetValue === '' })
  }

  handleReset = (event) => {
    event.preventDefault();
    const { data } = this.state
    this.setState({
      foundContracts: data,
      searchText: '',
      searchTextIsEmpty: true,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      searchText, data,
    } = this.state

    let curatedSearchString = searchText.toLowerCase()
    curatedSearchString = _.deburr(curatedSearchString)

    const foundContracts = this.findContracts(curatedSearchString, data)

    this.setState({ foundContracts })
  }

  searchText = () => {
    const {
      searchText, searchTextIsEmpty,
    } = this.state
    return (
      <Col xs={12} lg={3}>
        <form onSubmit={this.handleSubmit}>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <Button onClick={this.handleSubmit} variant="outline-secondary"><FaSearch style={{ pointerEvents: 'none' }} /></Button>
            </InputGroup.Prepend>
            <FormControl value={searchText} onChange={this.handleSearchInputChange} placeholder="Enter a Word" aria-describedby="basic-addon1" />
            <InputGroup.Append>
              <Button onClick={this.handleReset} hidden={searchTextIsEmpty} variant="outline-secondary"><FaUndoAlt  style={{ pointerEvents: 'none' }}/></Button>
            </InputGroup.Append>
          </InputGroup>
        </form>
      </Col>
    )
  }

  columnsDataTable = ([
    {
      name: 'Title',
      selector: 'title',
      sortable: true,
    },
    {
      name: 'Year',
      selector: 'year',
      sortable: true,
    },
    {
      name: 'Actions',
      cell: row => (
        <Dropdown >
          <Dropdown.Toggle      
            className="ddl-menu"   
          >
            <FaList className="icon-ddl-menu" />
          </Dropdown.Toggle>
  
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => contractV1PDF({})}><FaRegFilePdf className="icon-ddl-menu" /> Download</Dropdown.Item>
            <Dropdown.Item href="#/action-1"><FaRegEye className="icon-ddl-menu" /> Detail</Dropdown.Item>
            <Dropdown.Item href="#/action-1"><FaRegEdit className="icon-ddl-menu" /> Edit</Dropdown.Item>
            <Dropdown.Item href="#/action-1"><FaTrash className="icon-ddl-menu" /> Remove</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      allowOverflow: true,
      button: true,
    },
  ]);

  render() {
    const { loading, data, foundContracts } = this.state;
        
    return (
      <Container>
        <Row style={{ borderColor: 'rgb(0,0,0,0.5) !important' }}>
          <Col>
            <DataTable
              columns={this.columnsDataTable}
              progressPending={loading}
              data={foundContracts}
              pagination
              customStyles={customStyles}
              highlightOnHover
              pointerOnHover
              subHeader
              subHeaderComponent={this.searchText()}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default ContractsListPage;
