import React,{Component} from 'react';
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom';
import { Navbar,Nav, Image} from 'react-bootstrap';
import { LogOutAction } from '../../actions/appAction';
import { LinkContainer } from 'react-router-bootstrap'

class NavbarPage extends Component {
  componentWillMount(){

  }

  validationPathLogin = () => {
    const { location } = this.props;
    const { pathname } = location;
    return pathname === "/login";
  }

  validationAdministration = () => {
    const { location } = this.props;
    const { pathname } = location;
    const path1 = "/panel/contracts"
    const path2 = "/panel/users"

    return pathname === path1 || pathname === path2 ;
  }

  logOut = () => {
    const { logOut } = this.props;
    logOut();
  }
  renderNavigationBar(){
    const { location } = this.props;

    if(!this.validationPathLogin()){
      if(this.validationAdministration()) {
        return (
        <Navbar variant="dark" expand="lg" style={{backgroundColor: 'rgb(0, 60, 100)', background: 'rgb(0, 60, 100)'}}
        >
          <Navbar.Brand>
            <Link to="/panel/contracts">
              <img
                  src="/assets/img/icons/western.png"
                  width="140"
                  height="65"
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                  className="mb-1 ml-5"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto" activeKey={location.pathname}>
              <LinkContainer exact to="/panel/contracts">
                <Nav.Link >CONTRACTS</Nav.Link>
              </LinkContainer>
              <LinkContainer exact to="/panel/users">
                <Nav.Link >USERS</Nav.Link>
              </LinkContainer>
              <LinkContainer exact to="/panel/roles">
                <Nav.Link >ROLES</Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav className="ml-auto" activeKey={location.pathname}>
              <LinkContainer exact to="login">
                <Nav.Link onClick={() => this.logOut()}>LOG OUT</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        )
      }
      return (
        <Navbar expand="lg" 
        >
        <Navbar.Brand>
          <Link to="/">
            <img
                src="assets/img/icons/western2.png"
                width="140"
                height="65"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
                className="mb-1 ml-5"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto" activeKey={location.pathname}>
              <LinkContainer exact to="/energy">
                <Nav.Link>ENERGY</Nav.Link>
              </LinkContainer>
              <LinkContainer exact to="/smart-home">
                <Nav.Link>SMART HOME</Nav.Link>
              </LinkContainer>
              <LinkContainer exact to="/vehicle">
                <Nav.Link>VEHICLE</Nav.Link>
              </LinkContainer>
              <LinkContainer exact to="/water">
                <Nav.Link>WATER</Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav className="ml-auto" activeKey={location.pathname}>
              <LinkContainer exact to="/find-us">
                <Nav.Link>FIND US</Nav.Link>
              </LinkContainer>
              <LinkContainer exact to="/login">
                <Nav.Link>LOG IN</Nav.Link>
              </LinkContainer>
            </Nav>
        </Navbar.Collapse>
        </Navbar>
      ); 
    }
    return (null)
  }

  render() {
      return (
         this.renderNavigationBar()
    )
  }
}

const mapDispatchToProps = dispatch => ({
  logOut() {
    dispatch(LogOutAction());
  },
});

export default connect(null, mapDispatchToProps)(withRouter(NavbarPage));
