import Axios from 'axios';
import _ from 'lodash'
import {
    GET_USERS_LIST_FULFILLED, GET_USERS_LIST_PENDING, GET_USERS_LIST_REJECTED
} from '../actionTypes/userTypes';
 
export const getUsers = () => dispatch => { 
    dispatch({ type: GET_USERS_LIST_PENDING });
  return Axios.get('/api/users')
  .then((response) => {
    if (response.data.success) {
      dispatch({ type: GET_USERS_LIST_FULFILLED, payload: response.data });
      return response.data;
    }
    dispatch({ type: GET_USERS_LIST_REJECTED, payload: response.data });

    const { type } = response.data;
    const message = type ? response.data.message : 'The system is down, please try later or contact administrator.';
    const data = {
      ...response.data,
      unknown: true,
      message,
    };
    return data;
  })
  .catch((response) => {
    dispatch({ type: GET_USERS_LIST_REJECTED, payload: response.data });
    const data = {
      success: false,
      unknown: true,
      message: 'The system is down, please try later or contact administrator.',
    };
    return data;
  });
}
  